import round from 'lodash/round'
import { JPY_CURRENCY_CODE } from 'constants/account'
import { humanizeLongNumberWithLocale } from './formatters'
import { getLanguageCode } from './mws'

export const parseCurrency = (value, currencyCode, options = {}) => {
  const newValue = value || '0'

  return parseFloat(newValue).toLocaleString('en-US', {
    style: 'currency',
    currency: currencyCode || 'USD',
    ...options
  })
}

export const getCurrencyCode = (code = 'us') => {
  /* There are some cases when even with the default value set
      the code variable returns null so the next line is a safeguard */
  const countryCode = code || 'us'

  switch (countryCode.toLowerCase()) {
    case 'us':
      return 'USD'
    case 'ca':
      return 'CAD'
    case 'in':
      return 'INR'
    case 'mx':
      return 'MXN'
    case 'uk':
      return 'GBP'
    case 'jp':
      return 'JPY'
    case 'ae':
      return 'AED'
    case 'sa':
      return 'SAR'
    case 'pl':
      return 'PLN'
    case 'au':
      return 'AUD'
    default:
      return 'EUR'
  }
}

export function getCurrencySymbolFromCode(code = 'us') {
  switch (code?.toLowerCase()) {
    case 'us':
    case 'ca':
    case 'mx':
    case 'au':
    case 'sg':
      return '$'
    case 'in':
      return '₹'
    case 'uk':
      return '£'
    case 'jp':
      return '¥'
    case 'ae':
      return 'AED '
    case 'sa':
      return 'SAR '
    default:
      return '€'
  }
}

export const formatCurrencyWithCountryCode = (value, code) => {
  const countryCode = code || 'us'
  return parseCurrency(value, getCurrencyCode(countryCode.toLowerCase()))
}

export const getCurrencySymbol = code => {
  return parseCurrency(0, code).slice(0, -4)
}

export const formatCurrencyValue = val => {
  return round(val, 2).toFixed(2)
}

export const formatCurrencyValueWithSymbol = (
  val,
  code,
  prependMinus,
  decimalPlaces = 2
) => {
  let newVal = val
  if (newVal == null) {
    return ''
  }
  const formattedCode = code ? code.toLowerCase() : code
  let prefix = ''
  const currencySymbol = getCurrencySymbolFromCode(formattedCode)
  const languageCode = getLanguageCode(formattedCode)

  // when prependMinus is true and value is negative
  // prepend '-' sign and display absolute value
  if (newVal < 0 && prependMinus) {
    prefix = '-'
    newVal = Math.abs(newVal)
  }

  // if not rendering decimals we round down
  if (decimalPlaces === 0) {
    newVal = Math.floor(newVal)
  }

  const formattedAndRoundedValue = humanizeLongNumberWithLocale(
    newVal,
    languageCode,
    decimalPlaces,
    decimalPlaces
  )

  return `${prefix}${currencySymbol}${formattedAndRoundedValue}`
}

export const formatAccounting = number => {
  const num = number || 0.0

  if (num < 0) {
    return `($${Math.abs(num).toFixed(2)})`
  }

  return `$${parseFloat(num).toFixed(2)}`
}

export const currencyIsDecimalized = currencyCode => {
  return ![JPY_CURRENCY_CODE].includes(currencyCode?.toLowerCase())
}
